@use '@angular/material' as mat;
@import '@angular/material/theming';
@include mat.core();

$hl-blue: (
    50 : #DEEFFA, // hover colour
    100 : #BBDEFB,
    200 : #449FD4, // primary colours blue HL
    300 : #64B5F6,
    400 : #42A5F5,
    500 : #3C66B5, // secondary colours blue variant
    600 : #1E88E5,
    700 : #1976D2,
    800 : #2F417D, // secondary colours blue Dark
    900 : #0D47A1,
    A100: #82B1FF,
    A200: #448AFF,
    A400: #2979FF,
    A700: #2962FF,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    )
);
$hl-green: (
    50: #E8F5E9,
    100: #C8E6C9,
    200: #77C69B, // primary colours green HL
    300: #81C784,
    400: #66BB6A,
    500: #549f91, // secondary colours green variant
    600: #43A047,
    700: #388E3C,
    800: #2C635E, // secondary colours green Dark
    900: #1B5E20,
    A100: #B9F6CA,
    A200: #69F0AE,
    A400: #00E676,
    A700: #00C853,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    )
);

$hl-amber: (
    50: #ffffff,
    100: #fff7f4,
    200: #fecdbd,
    300: #fc9977,
    400: #fc8259,
    500: #E86C60, // error colour
    600: #ff0000,
    700: #ff4b64,
    800: #b83204,
    900: #bd2d2d,
    A100: #ffffff,
    A200: #fff7f4,
    A400: #fc8259,
    A700: #f44205,
    contrast: (
        50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    )
);


/* mat-palette(base-color, default?, light?, dark?) */
$hal-primary: mat.define-palette($hl-blue, 500, 200, 800);
$hal-accent:  mat.define-palette($hl-green, 500, 200, 800);
$hal-warn:    mat.define-palette($hl-amber, 500, 200, 800);
$hal-theme: mat.define-light-theme($hal-primary, $hal-accent, $hal-warn);
@include mat.all-component-themes($hal-theme);

$ocean-primary: mat.define-palette(mat.$light-blue-palette);
$ocean-accent:  mat.define-palette(mat.$cyan-palette, A200, A100, A400);
$ocean-warn:    mat.define-palette(mat.$amber-palette);
$ocean-theme: mat.define-light-theme($ocean-primary, $ocean-accent, $ocean-warn);
.t-ocean {
    @include mat.all-component-themes($ocean-theme);
}

$dessert-night-primary: mat.define-palette(mat.$amber-palette);
$dessert-night-accent:  mat.define-palette(mat.$yellow-palette, A200, A100, A400);
$dessert-night-warn:    mat.define-palette(mat.$red-palette);
$dessert-night-theme: mat.define-dark-theme($dessert-night-primary, $dessert-night-accent, $dessert-night-warn);
.t-dessert-night {
    @include mat.all-component-themes($dessert-night-theme);
}

$sidebar-primary: mat.define-palette($hl-blue, 500, 200, 800);
$sidebar-accent:  mat.define-palette($hl-green, 500, 200, 800);
$sidebar-warn:    mat.define-palette($hl-amber, 600, 500, 900);
$sidebar-theme: mat.define-dark-theme($sidebar-primary, $sidebar-accent, $sidebar-warn);
.t-sidebar {
    @include mat.all-component-themes($sidebar-theme);
}