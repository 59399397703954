@charset "UTF-8";
@import "variables.scss";
@import "dx.light";

.dx-drawer-wrapper {
  overflow: hidden;
}
.menu .dx-overlay-content {
  background-color: #3b444f;
  border-radius: 0 5px 5px 0;
  color: white;
  font-family: Roboto;
  font-weight: 500;
  font-size: 13px;
}
.menu .dx-list-item {
  border-top: none !important;
}
.dx-scrollable-native.dx-scrollable-native-ios .dx-scrollable-content {
  min-height: 100%;
}

/**
 * Put any style that required :ng-deep below. Always avoid using ::ng-deep in css
 */

// Override read only text boxes to be solid instead of dotted outlines.
dx-text-box.dx-texteditor.dx-state-readonly {
  border-style: solid !important;
}

.dx-tag-content {
  border-radius: 15px !important;
  background-color: #f1f5f8 !important;
  border: 1px solid #c5e5fd !important;
  color: #3b444f !important;
  font-weight: 500 !important;
  padding: 3px 25px 3px 8px !important;
}

.dx-tag-remove-button {
  margin-right: 3px;
}
.dx-datagrid-search-panel {
  margin-left: 0;
  width: 100%;
}

.action-column-menu {
  width: 20px;
  display: block;
  margin: 0 auto;
  .dx-menu-item-popout-container {
    display: none;
  }
  &.dx-menu .dx-menu-item {
    border-radius: 50%;
    &.dx-state-focused {
      background-color: unset;
      color: unset;
    }
    .dx-menu-item-content {
      width: 20px;
      height: 20px;
      padding: 0px;
    }
  }
  &.dx-menu .dx-icon {
    margin: auto !important;
  }
  .dx-menu-item-expanded {
    background-color: transparent;
  }
}

.dx-datagrid-header-panel {
  .add-row-btn {
    .dx-button {
      height: $dg-btn-height;
      width: $dg-btn-width-big;
      background-color: $secondary-blue-variant;
      color: $white;
      .dx-button-content {
        .dx-icon {
          display: none;
        }
        padding: 10px 18px;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
  .clear-all-btn {
    .dx-button {
      height: $dg-btn-height;
      width: $dg-btn-width-big;
      background-color: $white;
      color: $secondary-blue-variant;
      border-color: $secondary-blue-variant;
      .dx-button-content {
        .dx-icon {
          display: none;
        }
        padding: 10px 18px;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
  .new-stocktake-btn {
    .dx-button {
      height: $dg-btn-height;
      width: 138px;
      background-color: $secondary-blue-variant;
      color: $white;
      .dx-button-content {
        .dx-icon {
          display: none;
        }
        padding: 10px 18px;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
  .reset-grid-btn {
    .dx-button {
      height: $dg-btn-height;
      .dx-button-content {
        padding: 9px 18px;
        .dx-icon {
          display: none;
        }
      }
    }
  }
  .toolbar-title {
    color: #3c66b6;
    font-weight: 500;
    max-width: max-content !important;
  }
}

.dx-dropdownmenu-list .add-row-btn .dx-button {
  color: unset;
}

.dx-toolbar-items-container {
  .dx-button[aria-label="Save"]{
    height: $dg-btn-height;
    min-width: 100px;
    max-width: 120px;
    border: 2px solid $secondary-blue-variant;
    background-color: $secondary-blue-variant;
    color: $white;
  }
  .dx-button[aria-label~="OK"]{
    height: $dg-btn-height;
    min-width: 100px;
    max-width: 120px;
    border: 2px solid $secondary-blue-variant;
    background-color: $secondary-blue-variant;
    color: $white;
  }
  .dx-button[aria-label~="Abort"]{
    height: $dg-btn-height;
    min-width: 100px;
    max-width: 120px;
    border: 2px solid $secondary-blue-variant;
    background-color: $white;
    color: $secondary-blue-variant;
  }
  .dx-button[aria-label~="Cancel"]{
    height: $dg-btn-height;
    min-width: 100px;
    max-width: 120px;
    border: 2px solid $secondary-blue-variant;
    color: $secondary-blue-variant;
    opacity: 0.6;
  }
  .dx-button[aria-label="Clear"] {
    height: $dg-btn-height;
    min-width: 100px;
    max-width: 120px;
    border: 2px solid $secondary-blue-variant;
    background-color: $secondary-blue-variant;
    color: $white;
    opacity: 0.6;
  }
  .dx-button[aria-label="Yes"]{
    height: $dg-btn-height;
    min-width: 100px;
    max-width: 120px;
    border: 2px solid $secondary-blue-variant;
    background-color: $secondary-blue-variant;
    color: $white;
    &.dx-state-focused {
      border-color: #000000;
    }
  }
  .dx-button[aria-label~="No"]{
    height: $dg-btn-height;
    min-width: 100px;
    max-width: 120px;
    border: 2px solid $secondary-blue-variant;
    background-color: $white;
    color: $secondary-blue-variant;
    &.dx-state-focused {
      border-color: #000000;
    }
  }
}

.set-default-btn .dx-button {
  height: $dg-btn-height;
  width: $dg-btn-width;
  border: 2px solid $secondary-blue-variant;
  background-color: $secondary-blue-variant;
  color: $white;
}

.cog-menu-tree {
  &.dx-menu {
    .dx-button {
      border: none;
      border-radius: 50%;
      .dx-button-content {
        padding: 0;
        height: 36px;
        width: 36px;
      }
      .dx-icon {
        display: inline;
        font-size: 30px !important;
        margin: auto !important;
        color: $secondary-blue-variant;
        &.dx-icon-menu {
          // extends from CustomerPortal/node_modules/devextreme/dist/css/dx.light.css
          @extend .dx-icon-overflow; // this is to change default menu icon on mobile view
        }
      }
    }
  }
}

.cog-menu {
  .dx-menu-item-popout-container {
    display: none;
  }
  &.dx-menu .dx-menu-item {
    border-radius: 50%;
    .dx-menu-item-content {
      width: 40px;
      height: 40px;
    }
  }
  &.dx-menu .dx-icon {
    font-size: 30px;
    margin: auto !important;
    line-height: 100%;
    color: $secondary-blue-variant;
  }
  .dx-menu-item-expanded {
    background-color: transparent;
  }
}

.tax-model-container {
  padding-top: 10px;
  height: 100%;
}

.dx-datagrid-header-panel {
  .set-default-btn {
    .dx-button {
      height: $dg-btn-height;
      width: $dg-btn-width;
      background-color: $secondary-blue-variant;
      color: $white;
      .dx-button-content {
        .dx-icon {
          display: none;
        }
        padding: 9px 18px;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
}
.dx-dropdownmenu-list .set-default-btn .dx-button {
  color: unset;
}

.set-default-venue-list .generic-data-grid {
  height: calc(100% - 50px) !important;
}

.generic-data-grid {
  .dx-radiobutton {
    width: 20px;
    display: block;
    margin: 0 auto !important;
  }
  .dx-radiobutton-icon {
    width: 15px;
    height: 15px;
    &:before {
      width: 15px;
      height: 15px;
    }
  }
  .dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
    display: block;
    margin-top: -13px;
    margin-left: 4px;
    width: 9px;
    height: 9px;
    background: $secondary-blue-variant;
    content: "";
    border-radius: 5px;
  }
}

.no-border-top {
  .dx-form-group-with-caption > .dx-form-group-content {
    border-top: unset;
  }
}

.empty-template-label {
  line-height: 30px;
}

.action-button-center {
  justify-content: center;
}

.toolbar-label {
  font-size: 14px !important;
}

.form-switch-label-right {
  line-height: 6;
  .dx-field-item-content {
    width: unset !important;
    flex-grow: unset !important;
  }
  .dx-field-item-label {
    padding-left: 15px;
  }
}

.form-switch-label-right-aligned {
  padding-top: 32px !important;
  .dx-field-item-content {
    width: unset !important;
    flex-grow: unset !important;
  }
  .dx-field-item-label {
    padding-left: 15px;
  }
}

.form-check-box-label-right {
  line-height: 3;
  .dx-field-item-content {
    width: unset !important;
    flex-grow: unset !important;
  }
  .dx-field-item-label {
    padding-left: 15px;
  }
}

.customized-tab-panels {
  .dx-tabpanel-tabs {
    border-bottom: 1px solid #ced4da;
    margin-bottom: 10px;
    .dx-widget {
      background-color: #ffffff;
      color: #6B7280;
      box-shadow: unset !important;
      .dx-tab {
        background-color: #ffffff !important;
        box-shadow: unset !important;
      }
      .dx-tab.dx-tab-selected {
        border-bottom: 3px solid #3C66B6;
        .dx-tab-text {
          color: #3C66B6;
          font-weight: 500
        }
      }
      .dx-tab.dx-tab-selected.dx-state-focused {
        box-shadow: unset !important;
      }
    }
  }
}

.count-stocktake-tab-panel {
  .highlighted-info {
    background: #efeeee;
  }
  .factor-dropdown {
    padding: 10px 60px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    .dx-field-item-content-location-right {
      width: 58%;
    }
  }

  .enter-factors-counted {
    .dx-placeholder {
      text-align: left;
      width: 100%;
    }
    .dx-field-item-label-text {
      color: #6b7280;
      font-weight: unset;
    }
  }

  .dx-tab.dx-tab-selected {
    background-color: #3c66b6;
    .dx-tab-content {
      .dx-tab-text {
        color: #ffffff !important;
      }
    }
  }
}

.scan-search-wrapper {
  .dx-button {
    margin-left: 7px;
  }
}

.transfer-header-form {
  .lookup {
    min-width: 170px;
  }
}

.recipe-details {
  .cut-weight-item {
    .dx-field-item-label {
      width: max-content;
    }
  }

  .divide-by-cut-item {
    line-height: 6;
  }
  .dx-layout-manager .dx-label-h-align.dx-flex-layout .dx-field-item-content {
    width: unset;
    flex-grow: unset;
  }
}

.stock-details-tabs {
  .stock-details-template {
    .web-blurbs {
      .form-item {
        .dx-field-item-label {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
    .dx-accordion-item-title {
      background-color: #f2f2f2 !important;
      .dx-accordion-item-title-caption {
        color: #3b444f;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .dx-accordion-item {
      border-radius: 4px;
      border-top-color: transparent !important;
      margin-top: 4px;
    }
    .dx-accordion-item-opened {
      .dx-accordion-item-title {
        border-top: 1px solid #ddd !important;
      }
    }
    .dx-accordion-item-closed.dx-state-focused {
      border-top: 1px solid #ddd !important;
    }
  }
}
.dx-tabpanel-container {
  .dx-multiview-wrapper {
    border-color: transparent !important;
  }
}

.app-switcher-accordion {
  height: 90vh;
  overflow-y: auto;
  .dx-accordion-item {
    &.dx-state-focused {
      border-color: #ddd;
    }
    .dx-accordion-item-title {
      background-color: #59afba;
      color: #ffffff !important;
      &:before {
        color: unset;
      }
      .dx-accordion-item-title-caption {
        font-size: 20px;
      }
    }
  }
  .dx-accordion-item-body {
    padding: 0px 0px 0px;
  }
}

.factors-filter-popup-container {
  .factor-filter-container {
    .factors-filter-radio-group {
      .dx-widget.dx-collection {
        justify-content: space-around;
        .dx-item.dx-radiobutton {
          padding: 15px;
          margin: 0px;
        }
      }
    }
  }
}

.add-price-changes-popup {
  .rounding-value.dx-field-item {
    padding-top: 28px;
  }
}

.price-calculator-form {
  .item-span-divider {
    border-bottom: 1px solid #ddd;
  }
  .sales-tax-options {
    .tax-option-field {
      margin: 10px 0px;
      .label {
        display: inline-block;
        width: 25%;
      }
      .tax-options {
        display: inline-block;
        .option-item {
          display: inline-block;
          margin: 0px 10px;
        }
      }
    }
  }
  .rounding-value.dx-field-item {
    padding-top: 28px;
  }

  .negative-highlighted-info {
    .dx-texteditor-input-container {
      input {
        color: #d55152;
      }
    }
    &.dx-field-item {
      &.dx-col-4 {
        padding-left: unset;
        .dx-field-item-content {
          .dx-template-wrapper {
            background: #efeeee;
          }
        }
      }
    }
    .dx-field-item-content {
      .dx-template-wrapper {
        padding: 5px;
      }
    }
  }

  .highlighted-info {
    &.dx-field-item {
      &.dx-col-0 {
        padding-right: unset;
      }
      &.dx-col-2 {
        padding-left: unset;
      }
      &.dx-col-4 {
        padding-left: unset;
        .dx-field-item-content {
          .dx-template-wrapper {
            background: #efeeee;
          }
        }
      }
    }
    .dx-field-item-content {
      .dx-template-wrapper {
        padding: 5px;
        background: #dfdbdb;
      }
    }
  }

  .future-price {
    dx-check-box {
      margin-bottom: 10px;
      .dx-checkbox-text {
        font-weight: 400;
        font-size: 17px;
      }
    }
  }

  .future-price-list {
    .wrapper {
      display: flex;
      justify-content: space-between;
      dx-lookup {
        margin-bottom: 10px;
      }
      a {
        line-height: 2.5;
        margin-right: 10px;
      }
    }
  }
}

.assign-barcode-popup {
  .dx-toolbar-center {
    margin: unset !important;
  }
  .assign-barcode-form {
    .dx-field-item-label-text {
      color: #6b7280;
      font-weight: unset;
    }
  }
}

.barcode-prompt-barcode {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
}

.barcode-prompt-heading {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
}

.sales-stock-detail-wrapper {
  .dx-tabpanel > .dx-tabpanel-tabs .dx-tabs {
    display: none !important;
  }

  dx-form {
    .dx-field-item-label-text {
      color: #6b7280;
    }
    .dx-field-item-label-text[aria-label~="Points Override"]{
      width: 102px !important;
    }
  }
  .dx-accordion-item-title {
    background-color: #f2f2f2 !important;
    .dx-accordion-item-title-caption {
      color: #3b444f;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .dx-accordion-item {
    border-radius: 4px;
    border-top-color: transparent !important;
    margin-top: 4px;
  }
  .dx-accordion-item-opened {
    .dx-accordion-item-title {
      border-top: 1px solid #ddd !important;
    }
  }
  .dx-accordion-item-closed.dx-state-focused {
    border-top: 1px solid #ddd !important;
  }
  .dark-header.dx-item-content.dx-accordion-item-title {
    color: #3b444f;
    font-size: 16px;
    font-weight: 500;
  }
  .points-override-lbl {
    .dx-field-item-label {
      width: 102px;
    }
  }
}

.grouping-printers-wrapper {
  #labelImageUpload  {
    .dx-field-item-label {
      margin-left: 0px !important;
    }
  }
  app-image {
    .image-component {
      margin: 0px;
    }
    .note {
      margin: 0px;
    }
  }
  .dx-field-item-label {
    pointer-events: none;
  }
}

.stock-mobile {
  .stock-mobile-home {
    .header-wrapper {
      dx-toolbar {
        background: #3C66B6 0% 0% no-repeat padding-box;
        padding-top: 10px;
        .dx-toolbar-items-container {
          height: 45px;
          .dx-toolbar-before .dx-toolbar-item {
            img.imagePlaceHolder {
              margin-top: unset;
            }
          }
        }
      }
      dx-lookup {
        border: unset;
        background: #5177c1;
        width: 100%;
        .dx-lookup-field {
          color: #ffffff;
        }
        .dx-lookup-arrow {
          color: #ffffff;
        }
      }
    }
  }

  .stock-mobile-common-header {
    .toolbar-title {
      color: #3C66B6;
      font-weight: 500;
    }
  }

  .stock-info-quantity-container {
    .stock-info-quantity-wrapper {
      app-generic-data-grid {
        .generic-data-grid {
          width: unset;
        }
      }

      dx-button.go-back-button {
        border: 1px solid #3C66B6;
        color: #3C66B6;
        &.dx-state-hover {
          background-color: transparent;
        }
      }
    }
  }

  .wastage-container .wastage-wrapper {
    .create-wastage {
      dx-form label .dx-field-item-label-text {
        color: #6B7280;
        font-weight: unset;
      }
    }

    .wastage-item-details {
      dx-form {
        label .dx-field-item-label-text {
          font-weight: unset;
          color: #6B7280;
        }
        .quantity-input {
          input {
            font-size: 20px;
            text-align: center;
            font-weight: 500;
          }
        }
      }
    }

  }

  .discrepancy-container .discrepancy-wrapper {
    .create-discrepancy {
      dx-form label .dx-field-item-label-text {
        color: #6B7280;
        font-weight: unset;
      }
    }

    .discrepancy-item-details {
      dx-form {
        label .dx-field-item-label-text {
          font-weight: unset;
          color: #6B7280;
        }
        .quantity-input {
          input {
            font-size: 20px;
            text-align: center;
            font-weight: 500;
          }
        }
      }
    }

  }

  .transfer-container .transfer-wrapper {
    .create-transfer {
      dx-form label .dx-field-item-label-text {
        color: #6B7280;
        font-weight: unset;
      }
    }

    .transfer-details {
      dx-form {
        label .dx-field-item-label-text {
          font-weight: unset;
          color: #6B7280;
        }
        .quantity-input {
          input {
            font-size: 20px;
            text-align: center;
            font-weight: 500;
          }
        }
      }
    }

  }

}

.purchase-order-details {
  .header-form {
    .text-area-field {
      textarea {
        height: 10px;
        overflow: hidden;
      }
      &.dx-state-focused {
        textarea {
          overflow: auto;
        }
      }
    }
    .company-details-field, .delivery-details-field {
      .dx-texteditor-input {
        text-overflow: ellipsis;
      }
    }
  }
}

.purchase-order-company-details-popup {
  dx-form {
    .company-sub-group {
      border-right: 1px solid #ddd;
      margin-right: 15px;
      height: 100%;
    }
    .address-sub-group {
      background: #FDFDFD;
      border: 1px solid #EFEFEF;
      border-radius: 4px;
      opacity: 1;
      padding: 15px;
      .label {
        min-width: 200px;
        margin-right: 5px;
      }
      .border {
        width: 73%;
        border-top: 1px solid #ddd;
        position: relative;
        top: 10px;
      }
    }
  }
}

.purchase-order-delivery-details-popup {
  dx-form {
    .sub-group {
      background: #FDFDFD;
      border: 1px solid #EFEFEF;
      border-radius: 4px;
      opacity: 1;
      padding: 15px;
      &.address {
        margin-bottom: 15px;
      }
      .label {
        min-width: 200px;
        margin-right: 5px;
      }
      .border {
        width: 88%;
        border-top: 1px solid #ddd;
        position: relative;
        top: 10px;
      }
    }
  }
}

.productsMenu .dx-overlay-content {
  background-color: #3B444F;
  border-radius: 5px;
  color:white;
  font-family: Roboto;
  font-weight: 500;
  font-size: 13px;
}
.productsMenu .dx-list-item {
  border-top: none !important;
  .dx-list-item-content {
    white-space: normal;
  }
}

@media screen and (max-width: 768px) {
  .purchase-order-company-details-popup {
    dx-form {
      .company-sub-group {
        border-right: unset;
        margin-right: unset;
      }

      .address-sub-group {
        margin-top: 20px;
        padding: 15px !important;
      }
    }
  }

  .purchase-order-delivery-details-popup {
    dx-form {
      .sub-group {
        padding: 15px !important;
      }
    }
  }
}



@media screen and (max-width: 1400px) {
  .stocktake-report-wrapper {
    .dx-item.dx-box-item {
      display: block !important;
      flex: unset !important;
    }
  }
  .offer-issuing-wrapper {

    .offer-issuing-container {
      .form-switch-label-right {
        margin-top: 15px;
        line-height: 1;
        .dx-field-item-label-text {
          white-space: normal;
        }
      }
    }

  }
}

@media screen and (max-width: 1000px) {
  .stocktake-items-tabbed-grid {
    app-generic-data-grid {
      .generic-data-grid {
        width: unset;
      }
    }
  }

  .stocktake-entry-count-list-grid {
    app-generic-data-grid {
      .generic-data-grid {
        width: unset;
      }
    }
  }

  .product-detail-wrapper {
    .barcodes-grid-container {
      app-generic-data-grid {
        .generic-data-grid {
            width: unset;
        }
      }
    }
    .package-details-container {
      .form-switch-label-right {
        margin-top: 15px;
        line-height: 1;
        .dx-field-item-label-text {
          white-space: normal;
        }
      }
    }
    .substitutes-grid-container{
      app-generic-data-grid {
        .generic-data-grid {
            width: unset;
        }
      }
    }
  }

  .product-in-location-detail-wrapper {
    .form-switch-label-right {
      margin-top: 15px;
      line-height: 1;
      .dx-field-item-label-text {
        white-space: normal;
      }
    }
  }

  .product-detail-pricing-wrapper {
    .pricing-grid-container {
      app-generic-data-grid {
        .generic-data-grid {
            width: unset;
        }
      }
    }
    .form-switch-label-right {
      margin-top: 15px;
      line-height: 1;
      .dx-field-item-label-text {
        white-space: normal;
      }
    }
  }

  .mix-and-match-detail-wrapper {
    .optional-details-field {
      white-space: nowrap;
    }
    .form-switch-label-right {
      line-height: 1;
      .dx-field-item-content {
        width: unset !important;
        flex-grow: unset !important;
      }
      .dx-field-item-label {
        padding-left: 15px;
        text-wrap: wrap;
      }
    }
    .location-details {
      .dx-item {
        .dx-item-content {
          flex-direction: column !important;
          .dx-flex-layout {
            padding-left: 0px;
          }
        }
      }
    }
  }
  .sales-special-detail-wrapper {
    .sales-special-products-in-location-list-data-grid-container {
      app-generic-data-grid {
        .generic-data-grid {
          width: unset;
        }
      }
    }
  }
}

#salesStockDetailForm {
  .dx-field-item-help-text {
    color: #6b7280;
    opacity: 1;
  }
}
#labelImageUpload {
  .dx-layout-manager {
    .dx-field-item {
      &:not(.dx-last-col) {
        padding-right: 0px;
      }
      &:not(.dx-first-col) {
        padding-left: 0px;
      }
    }
    .dx-collection {
      .dx-item {
        .dx-item-content {
          .dx-field-item-label {
            margin-left: 45px;
          }
          .dx-field-item-content {
            display: flex;
          }
        }
      }
    }
  }
}
.dx-field-item-label {
  margin-bottom: 5px;
}
.dx-state-focused.dx-accordion-item {
  border-color: #d9d9d9;
}

.venue-location-detail-tab-template {
  .default-price {
    white-space: nowrap;
  }
  .form-switch-label-right {
    line-height: 6;
    @media screen and (max-width: 768px) {
      line-height: 1;
    }
    .dx-field-item-content {
      width: unset !important;
      flex-grow: unset !important;
    }
    .dx-field-item-label {
      padding-left: 15px;
    }
  }
  .points-field {
    padding-left: 0 !important;
  }
  .validation-message-top {
    .dx-invalid-message > .dx-overlay-content {
      transform: translate(0px, -100%) !important;
    }
  }
}

.mix-and-match-purchase-requirement-data-grid {
  @media only screen and (max-width: 600px) {
    .dx-datagrid-header-panel {
      display: none;
    }
  }
  .dx-datagrid-focus-overlay {
    border: 0px
  }
}

.offer-rules-data-grid, .offer-details-venue-location-data-grid {
  @media only screen and (max-width: 600px) {
    .dx-datagrid-header-panel {
      display: none;
    }
  }
  .dx-datagrid-focus-overlay {
    border: 0px
  }
}

.sales-stock-accordion-container, .product-in-location-accordion-container {
  .dx-field-item-label-text {
    color: #6b7280;
    margin-bottom: 5px;
  }
  .dx-form-group-with-caption {
    margin-top: 10px;
    .dx-form-group-caption {
      font-size: 16px !important;
      font-weight: 500;
    }
    .dx-form-group-content {
      border-top: 0px;
      margin-top: 3px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}

.sales-stock-detail-header-form {
  .factor-item {
    .dx-lookup-empty .dx-lookup-field {
      color: unset;
    }
  }

  .dx-layout-manager .dx-label-h-align.dx-flex-layout {
    flex-direction: column;

    .dx-field-item-label
      .dx-field-item-label-content
      .dx-field-item-label-text {
      color: #6b7280;
    }
  }
}

.receive-details {
  dx-form {
    .comment-group {
      .comment-view-btn {
        line-height: 5.9;
        padding-left: unset;
      }
    }
  }
}

.user-management-detail-container {
  .user-management-detail-item.user-detail-form {
    .form-btn-container {
        dx-button {
          img {
            height: 13px;
            width: 13px;
          }
        }
      }
    }
}

.user-management-assign-role-popup {
  .assign-role-form {
    .assign-venue {
      .dx-texteditor-input-container.dx-tag-container.dx-native-click::after {
        content: unset;
      }
    }
  }
}

.product-detail-wrapper, .product-in-location-detail-wrapper, .venue-location-detail-wrapper, .mix-and-match-detail-wrapper, .offer-detail-wrapper, .stock-creation-template-details-wrapper, .add-stock-item-accordion-wrapper, .product-group-detail-wrapper {
  .product-details-template, .product-in-location-details-template, .venue-location-detail-tab-template, .mix-and-match-details-template, .offer-details-template, .stock-creation-template-details-accordion, .add-stock-item-accordion, .product-group-details-accordion {
    .dx-accordion-item-title {
      background-color: #f2f2f2 !important;
      .dx-accordion-item-title-caption {
        color: #3b444f;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .dx-accordion-item {
      border-radius: 4px;
      border-top-color: transparent !important;
      margin-top: 4px;
    }
    .dx-accordion-item-opened {
      .dx-accordion-item-title {
        border-top: 1px solid #ddd !important;
      }
    }
    .dx-accordion-item-closed.dx-state-focused {
      border-top: 1px solid #ddd !important;
    }
    .images-template-wrapper {
      #labelImageUpload  {
        .dx-field-item-label {
          margin-left: 0px !important;
        }
      }
      app-image {
        .image-component {
          margin: 0px;
        }
        .note {
          margin: 0px;
        }
      }
      .dx-field-item-label {
        pointer-events: none;
      }
    }
  }
}

.product-group-detail-wrapper {
  .product-group-details-accordion {
    dx-accordion {
      .dx-accordion-item-body {
        padding: 8px 12px 34px;
      }
    }
    .form-switch-label-right {
      @media screen and (max-width: 767px) {
        line-height: unset;
      }
    }
  }
}

.product-detail-wrapper {
  .product-details-template {
    .dx-accordion-item-title {
      background-color: #f2f2f2 !important;
      .dx-accordion-item-title-caption {
        color: #3b444f;
        font-size: 16px;
        font-weight: 500;
      }
    }
    .dx-accordion-item {
      border-radius: 4px;
      border-top-color: transparent !important;
      margin-top: 4px;
    }
    .dx-accordion-item-opened {
      .dx-accordion-item-title {
        border-top: 1px solid #ddd !important;
      }
    }
    .dx-accordion-item-closed.dx-state-focused {
      border-top: 1px solid #ddd !important;
    }
    .images-template-wrapper {
      #labelImageUpload  {
        .dx-field-item-label {
          margin-left: 0px !important;
        }
      }
      app-image {
        .image-component {
          margin: 0px;
        }
        .note {
          margin: 0px;
        }
      }
      .dx-field-item-label {
        pointer-events: none;
      }
    }
  }
}

.multi-item-barcode-container {
  dx-form {
    .add-btn-group .dx-item.dx-box-item {
      &:nth-child(2) {
        align-items: end;

        dx-button {
          font-size: 14px;
        }
      }
    }
  }
  dx-data-grid {
    .dx-link.dx-link-delete {
      margin-left: unset;
    }
  }
}


.purchase-requirements-wrapper {
  .title-buttons {
    dx-button.save-btn {
      margin-left: 5px;
    }
  }
}

.offer-rules-wrapper {
  .title-buttons {
    dx-button.save-btn {
      margin-left: 5px;
    }
  }
}

#products-in-locations-list-grid, #sales-special-products-in-location-list-data-grid {
  .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-removed)>td.grid-cell-background-grey {
      background-color: #aac4f5 !important;
      color: #333;
    }
  .grid-cell-background-grey {
    background-color: #F2F2F2 !important;
    color: #333;
  }
}

.venue-location-detail-wrapper {
  dx-tab-panel {
    &.location-details-tabs{
      .dx-item.dx-tab:nth-child(1) {
        width: 200px;
      }
    }
  }
  .venue-location-detail-tab-template {
    .stock-control {
      .form-switch-label-right {
        line-height: unset;
        .dx-field-item-label-text {
          white-space: normal;
        }
      }
    }

    .other-option {
      .form-switch-label-right {
        line-height: unset;
        .dx-field-item-label-text {
          white-space: normal;
        }
      }
    }
  }
}

.mix-and-match-detail-wrapper {
  .mix-and-match-details-template {
    .title-wrapper {
      &.purchase-requirement {
        dx-button {
          height: 24px;
          font-size: 16px;
          margin-right: 15px;
          .dx-button-content {
            padding: 2px 10px 2px;
          }
        }
      }
    }
  }
}

.offer-detail-wrapper {
  .offer-details-template {
    .title-wrapper {
      &.offer-accordion-title {
        dx-button {
          height: 24px;
          min-width: 40px;
          font-size: 16px;
          margin-right: 15px;
          .dx-button-content {
            padding: 2px 10px 2px;
          }
        }
      }
    }
  }
}

.add-sales-special-product-in-locations-popup {
  dx-form {
    .add-selected-btn {
      text-align: right;
    }
  }
}

.stock-creation-template-details-wrapper {
  .stock-creation-template-details-accordion {
    .default-stock-form {
      .form-switch-label-right {
        line-height: unset;
      }
      .dx-form-group-caption {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 1370px) {
  #labelImageUpload {
    .dx-layout-manager {
      .dx-collection {
        .dx-item {
          .dx-item-content {
            .dx-field-item-label {
              margin-left: 30px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 375px) {
  #labelImageUpload {
    .dx-layout-manager {
      .dx-collection {
        .dx-item {
          .dx-item-content {
            .dx-field-item-label {
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
}

/**
 * Theme builder generated - Font family related
 */
.dx-theme-generic-typography {
  font-family: $font-family;
}
.dx-theme-generic-typography input,
.dx-theme-generic-typography textarea {
  font-family: $font-family;
}
.dx-theme-generic-typography a {
  color: $secondary-blue-variant;
}

.dx-widget {
  font-family: $font-family;
}
.dx-widget input,
.dx-widget textarea {
  font-family: $font-family;
}

.dx-field {
  font-family: $font-family;
}
.dx-field input,
.dx-field textarea {
  font-family: $font-family;
  line-height: 1.35715;
}
.dx-overlay-wrapper {
  font-family: $font-family;
}
.dx-overlay-wrapper input,
.dx-overlay-wrapper textarea {
  font-family: $font-family;
}
.dx-menu-base {
  font-family: $font-family;
}
.dx-menu-base input,
.dx-menu-base textarea {
  font-family: $font-family;
}
.dx-datagrid-column-chooser {
  font-family: $font-family;
}
.dx-datagrid-column-chooser input,
.dx-datagrid-column-chooser textarea {
  font-family: $font-family;
}
.dx-treelist-column-chooser {
  font-family: $font-family;
}
.dx-treelist-column-chooser input,
.dx-treelist-column-chooser textarea {
  font-family: $font-family;
}
.dx-pivotgrid .dx-ie .dx-pivotgrid-fields-area {
  font-family: $font-family;
}
.dx-pivotgrid .dx-ie .dx-pivotgrid-fields-area input,
.dx-pivotgrid .dx-ie .dx-pivotgrid-fields-area textarea {
  font-family: $font-family;
}
.dx-menu-base {
  font-family: $font-family;
}
.dx-menu-base input,
.dx-menu-base textarea {
  font-family: $font-family;
}

/**
 * Theme builder generated - Data Grid related
 */

.dx-datagrid .dx-header-filter {
  color: $dg-color;
}

.dx-datagrid .dx-header-filter-empty {
  color: rgba(51, 51, 51, 0.5);
}

.dx-datagrid-column-chooser
  .dx-overlay-content
  .dx-popup-content
  .dx-column-chooser-item {
  opacity: 0.5;
  color: $dg-color;
  font-weight: $dg-font-weight-bold;
}

.dx-datagrid-drag-header {
  color: $dg-color;
  font-weight: $dg-font-weight-bold;
}

.dx-datagrid-headers {
  color: $dg-color;
  font-weight: $dg-font-weight-bold;
}

.dx-datagrid-group-panel .dx-group-panel-message {
  color: $dg-color;
  font-weight: $dg-font-weight-bold;
}
.dx-datagrid-group-panel .dx-group-panel-item {
  color: $dg-color;
  font-weight: $dg-font-weight-bold;
}
.dx-datagrid-group-panel .dx-block-separator {
  color: $dg-color;
  font-weight: $dg-font-weight-bold;
}

.dx-popup-title.dx-toolbar .dx-toolbar-items-container {
  font-weight: $dg-font-weight-medium;
}

.dx-form-group-caption {
  font-weight: $dg-font-weight-medium;
}

.dx-field-item-help-text,
.dx-field-item-label-text {
  font-weight: $dg-font-weight-medium;
  color: #6b7280;
}

/**
 * Theme builder generated - Buttons related
 */

.dx-button-mode-contained.dx-button-default {
  background-color: #3c66b6;
  height: 36px;
  font-size: 12px;
  .dx-button-content {
    line-height: 18px;
  }
}

.dx-button-mode-contained.dx-button-default.pop-up {
  font-size: 14px;
  height: 36px;

  &.stock-mobile-alert {
    border-radius: 15px;
  }
  .dx-button-content {
    line-height: 18px;
  }
}

.dx-button-mode-outlined.dx-button-default.pop-up {
  font-size: 14px;
  height: 36px;
  border: 2px solid #3c66b6;
  color: #3c66b6;
  opacity: 0.6;
  .dx-button-content {
    line-height: 18px;
  }
}

.custom-pop-up-text-bold {
  font: normal normal 500 14px/19px Roboto;
}

.user-menu-tree {
  .user-icon {
    border-color: #acb5c1;
    border-width: 1px;
    border-style: solid;
    border-radius: 50%;
    padding: 7px;
    font-size: 20px !important;
    color: #acb5c1;
  }

  &.dx-menu .dx-button {
    border: 2px;
    color: #acb5c1;
    border-radius: 50%;
    margin-bottom: 10px;

    .dx-button-content {
      padding: 0;
      height: 36px;
      width: 36px;
    }

    .dx-icon {
      display: inline-table;
      margin: auto !important;
      &.dx-icon-menu {
        // extends from CustomerPortal/node_modules/devextreme/dist/css/dx.light.css
        @extend .dx-icon-user; // this is to change default menu icon on mobile view
        @extend .user-icon;
      }
    }
  }

  &.dx-overlay-content {
    max-width: 300px !important;
  }

  .dx-treeview-node-container:first-child > .dx-treeview-node {
    margin-right: 20px;

    &:first-child {
      margin-left: 25px;
      padding-top: 25px;
      padding-bottom: 25px;
      border-bottom: 0px;
    }

    &:nth-child(2) {
      margin-left: 18px;
      padding-top: 15px;
      padding-bottom: 15px;
      border-top: 1px solid #ddd;
    }

    &:last-child {
      margin-left: 18px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .dx-treeview-expander-icon-stub {
    width: 0;
    height: 0;
  }

  .dx-treeview-item {
    gap: 0;
  }

  .dx-treeview-item-content {
    padding-left: 0px !important;
  }

  .dx-treeview-item {
    &.dx-state-hover {
      background-color: transparent;
    }

    &.dx-state-active {
      background-color: transparent;
      color: black;

      .dx-list-slide-item-content {
        background-color: transparent;
        color: black;
      }
    }
  }

  .dx-treeview-item-without-checkbox {
    &.dx-state-focused > .dx-treeview-item {
      background-color: transparent;
      color: black;
    }

    &.dx-treeview-node-is-leaf:not(:first-child):hover {
      background-color: rgba(0, 0, 0, 0.04);
      color: #333;
      cursor: pointer;
      position: relative;
      margin-left: 0px;
      padding-left: 19px;
      margin-right: 0px;
      padding-right: 20px;
    }
  }

  &.dx-overlay-content .dx-treeview {
    margin-top: 10px;
  }

  .dx-button-mode-contained {
    &.dx-state-active,
    &.dx-state-focused {
      background-color: transparent;
      border-color: #ddd;
    }
  }

  &.stock-mobile.dx-menu {
    .dx-menu-adaptive-mode {
      background-color: transparent;
    }
    .dx-button {
      background-color: transparent;
      .dx-icon {
        &.dx-icon-menu {
          color: #ffffff;
          border-color: #ffffff;
        }
      }
    }

  }
}
//START - Common styles for toggle switch
.dx-switch-on-value {
  .dx-switch-container {
    border-radius: 15px;
    width: 50px;
    border: 1px solid #2f5aad;
    .dx-switch-on {
      color: #3c66b6;
      font-weight: 600;
    }
    .dx-switch-handle {
      &:before {
        background-color: #3c66b6;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        margin: 0px -4px -4px -5px;
      }
    }
  }
}
 .dx-switch-container {
   border-radius: 15px;
   width: 50px;
   border: 1px solid #cccaca;
   .dx-switch-off {
    color: #9d9d9d;
    font-weight: 600;
   }
   .dx-switch-handle {
     &:before {
       background-color: #9d9d9d;
       border-radius: 50%;
       height: 18px;
       width: 18px;
       margin: 0px -4px -4px 1px;
     }
   }
 }
 .dx-show-invalid-badge.dx-switch.dx-swipeable.dx-state-focused.dx-switch-on-value  {
  .dx-switch-container {
    border-radius: 15px;
    width: 50px;
    border: 1px solid #3c66b6 !important;
    .dx-switch-on {
      color: #3c66b6 !important;
      font-weight: 600;
    }
    .dx-switch-handle {
      &:before {
        background-color: #3c66b6 !important;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        margin: 0px -4px 0px -5px;
      }
    }
  }
}
.dx-show-invalid-badge.dx-switch.dx-swipeable.dx-state-focused {
  .dx-switch-container {
    border-radius: 15px;
    width: 50px;
    border: 1px solid #9d9d9d;
    .dx-switch-off {
     color: #9d9d9d ;
     font-weight: 600;
    }
    .dx-switch-handle {
      &:before {
        background-color: #9d9d9d;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        margin: 0px -4px 0px 1px;
      }
    }
  }
 }
//END - Common styles for toggle switch
.dx-toolbar-label .dx-toolbar-item-content {
  width: fit-content;
}
.stock-detail-wrapper, .venue-location-detail-wrapper {
  .dx-tabpanel-tabs {
    border-bottom: 1px solid #ced4da;
    margin-bottom: 10px;
    .dx-widget {
      background-color: #ffffff;
      color: #6B7280;
      box-shadow: unset !important;
      .dx-tab {
        background-color: #ffffff !important;
        box-shadow: unset !important;
      }
      .dx-tab.dx-tab-selected {
        border-bottom: 3px solid #3C66B6;
        .dx-tab-text {
          color: #3C66B6;
          font-weight: 500
        }
      }
      .dx-tab.dx-tab-selected.dx-state-focused {
        box-shadow: unset !important;
      }
    }
  }

  @media screen and (max-width: 900px) {
    .cost-form {
      .dx-item .dx-item {
        &:first-child {
          margin-right: 30px;
        }
      }
    }
  }
}

.dx-tabpanel-tabs-position-top.dx-state-focused .dx-tabpanel-tab.dx-state-disabled:not(.dx-state-focused) {
  border-block-end: 1px solid #ddd !important;
}

// --- Assign Supplier Grid Pop-Up CSS - Start ---
#assignSupplierCodePopUp>div>div.dx-popup-content {
  overflow-y: auto;
  overflow-x: hidden;
}

#assignSupplierCodeStockGrid {
  .dx-show-invalid-badge.dx-selectbox.dx-textbox.dx-texteditor.dx-dropdowneditor-button-visible.dx-editor-outlined.dx-widget.dx-dropdowneditor.dx-dropdowneditor-field-clickable {
    width: 72px !important;
    min-width: 60px;
  }
}

#assignSupplierCodeForm {
  span.dx-form-group-caption {
    text-align: left;
    font: normal 500 16px/21px Roboto;
    letter-spacing: 0px;
    color: #3B444F;
    opacity: 1;
  }

  div.dx-form-group-content {
    border-top: 0px;
    padding-top: 0px;
    padding-bottom: 5px;

    .dx-field-item-label-text {
      text-align: left;
      font: normal normal 500 14px/24px Roboto;
      letter-spacing: 0px;
      color: #6B7280;
      opacity: 1;
    }
  }
}
// --- Assign Supplier Grid Pop-Up CSS - End ---

.dx-link-delete {
  color: #f06969 !important;
  margin-left: 5px;
}
.dx-link-cancel {
  color: #858484 !important;
  margin-left: 5px;
}
.modal-yes {
  height: $dg-btn-height;
  width: $dg-btn-width;
  border: 2px solid $secondary-blue-variant;
  background-color: $secondary-blue-variant;
  color: $white;
  margin: 10px 0px 0px 10px;
}
.modal-no {
  height: $dg-btn-height;
  width: $dg-btn-width;
  border: 2px solid $secondary-blue-variant;
  color: $secondary-blue-variant;
  opacity: 0.6;
  margin: 10px 0px 0px 10px;
}
app-stocktake-details {
  .dx-accordion-item-title {
    background-color: #f2f2f2 !important;
    div {
      color: #3b444f;
      font-size: 16px;
      font-weight: 500;
    }
    .dx-accordion-item-title-caption {
      color: #3b444f;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .dx-accordion-item {
    border-radius: 4px;
    border-top-color: transparent !important;
    margin-top: 4px;
  }
  .dx-accordion-item-opened {
    .dx-accordion-item-title {
      border-top: 1px solid #ddd !important;
    }
  }
  .dx-accordion-item-closed.dx-state-focused {
    border-top: 1px solid #ddd !important;
  }
}
app-stock-list {
  .dx-tabpanel.dx-state-focused > .dx-tabpanel-tabs .dx-tabs {
    box-shadow: unset !important;
  }
}
.dx-link-save {

  color: #3C66B6 !important;

  margin-left: 5px;

}

#processTransferPopUp>div>div.dx-popup-content .dialog-container {
  display: flex;
    flex-direction: column;
    align-items: center;
    .dialog-title {
        padding: 10px;
        font-size: 14px;
        font-weight: 400;
    }
    .button {
      padding: 20px;
      .ok-btn {
        border-radius: 15px;
        background-color: #3c66b6;
        color: #ffffff;
        height: 34px;
        width: 98px;
      }
    }

}

#unprocessedReceivedListDataGrid {
  .dx-buttongroup-wrapper.dx-widget.dx-collection {
    .dx-buttongroup-item-content {
        background-color: #3c66b6;
        color: #fff;
        font-size: 12px;

        div.dx-button-content > i.dx-icon {
            color: #fff;
        }
    }
  }

  .dx-row.dx-data-row.dx-row-lines.dx-column-lines {
    td > span.credit-note {
        color: #7CCE92;
        border: 2px solid #7CCE92;
        background-color: #EDFFF0;
        border-radius: 5px;
        padding: 2px 9px;
        font-weight: 400;
        font-size: 13px;
    }
  }
}

#product-in-location-pricing-grid > div.dx-datagrid.dx-gridbase-container.dx-datagrid-borders {
  .dx-item.dx-box-item {
    .dx-item-content.dx-box-item-content {
      justify-content: center;
    }
  }
}

.sales-specials-list-wrapper {
  .sales-special-detail-wrapper {
    .dx-toolbar-items-container {
      height: 36px;
    }
  }
  .dx-toolbar-items-container {
    height: 76px;
    .dx-toolbar-before, .dx-toolbar-after {
      display: flex;
      align-items: flex-end;
    }
  }
  .toolbar-label {
    color: #6B7280;
    height: 28px;
  }
  .toolbar-label-to {
    color: #6B7280;
    height: 28px;
    margin-right: 10px;
    font-size: 14px;

  }
  .toolbar-title {
    color: #6B7280;
    font-size: 14px;
  }
}

.find-and-set-prices-list-container {
  .dx-toolbar-items-container {
    height: 66px;
    .dx-toolbar-after {
      padding-top: 30px;
    }
    .lookup-with-label {
      font-size: 14px;
      .dx-item-content.dx-toolbar-item-content > div:first-child {
        font-weight: 500;
        padding-bottom: 10px;
      }
    }
  }
}

.publish-price-changes-popup {
  .form-switch-label-right {
    line-height: 4;
  }
}

#publishPriceChangesPopup .dx-popup-content{
  padding: 10px 12px !important;
}
