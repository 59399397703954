@import '@angular/material/theming';

/*$app-primary: mat-palette($mat-grey);*/

/* colour */
$primary-blue-hl: #449fd4; // mat-palette($hl-blue, 200)
$primary-green-hl: #77c69b;
$secondary-blue-variant: #3c66b6;
$secondary-blue-dark: #2f417d;
$secondary-green-variant: #549f91;
$secondary-green-dark: #2c635e;
$white: #ffffff;
$grey-light: #eeeeee;
$grey: #a8a8a8;
$grey-oil: #495057;
$grey-dark: #2f2f2f;
$grey-blue: #313d4f;
$default-font-color: rgba(0, 0, 0, 0.87);
$colour-error: #e86c60;
$dark-theme-text-colour: #ffffff;
$dark-theme-bg-colour: $grey-blue;
$row-hover-colour:$grey-light;
$dg-color:#333;
$dg-font-weight-bold: 700;
$dg-font-weight-medium: 500;


/* font-size */
$text-size-h1: 60px;
$text-size-h2: 30px;
$text-size-h3: 12px;
$text-size-desktop: 20px;
$text-size-mobile: 16px;
$text-size-list-mobile: 17px;
$text-size-small: 12px;
$text-size-h2-mobile: 24px;

/* font colour */
$light-theme-primary-text-colour: $grey-dark;
$light-theme-secondary-text-colour: $grey-oil;
$light-theme-disabled-text-colour: $grey;
$dark-theme-primary-text-colour: $white;
$dark-theme-secondary-text-colour: $grey-light;
$dark-theme-disabled-text-colour: $grey;

/* background colour */
$bg-colour: $white;
$light-bg-colour: $grey-light;
$dark-bg-colour: $grey-dark;

/* buttons */
$btn-width-desktop: 250px;
$btn-width-desktop-small: 190px;
$btn-height-desktop: 60px;
$btn-width-mobile: 190px;
$btn-height-mobile: 60px;
$btn-active-colour: $secondary-blue-variant;
$btn-hover-colour: $secondary-blue-dark;
$btn-inactive-colour: $primary-blue-hl;
$btn-disabled-colour: $grey;
$btn-secondary-bg-colour: $white;
$btn-width-2-desktop: 189px;
$btn-height-2-desktop: 60px;
$btn-width-2-mobile: 162px;
$btn-height-2-mobile: 50px;

/* Form */
$input-active-border: solid 0.5px $grey-dark;
$input-hover-border: solid 2px $grey-dark;
$input-disabled-border: solid 0.5px $grey;
$input-selected-border: solid 2px $secondary-blue-variant;
$input-error-border: solid 2px $colour-error;

/* other */
$sidebar-width-desktop: 295px;
$sidebar-width-mobile: 267px;
$header-height-desktop: 130px;
$header-height-mobile: 90px;
$lists-focus-box-shadow: 0px 0px 4px $grey;
$font-family: Roboto, "Helvetica Neue", sans-serif; 

/* data grid */
$dg-btn-height: 36px;
$dg-btn-width-big: 120px;
$dg-btn-width: 100px;

/* media sizes */
$smartphone-max-portrait-width: 430px;