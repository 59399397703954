/* You can add global styles to this file, and also import other style files */
// @import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "variables.scss";
@import "dx.light";
@import "dx.common";
@import "devextreme-custom.scss";

html,
body {
  height: 100%;
  overscroll-behavior-x: none;
} // disable default browser x scroll event (page back and forward)
body {
  margin: 0;
  font-family: $font-family;
}

.cdk-overlay-container {
  z-index: 10000 !important;
}

.right {
  float: right;
}

.left {
  float: left;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.m-10 {
  margin: 10px;
}

body {
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 500;
}

.spacer {
  flex: 1 1 auto;
}

.data-grid-container {
  margin-top: 10px;
  height: calc(100vh - 201px);
  min-height: 275px;
}

.data-grid-container-with-sub-tabs {
  margin-top: 10px;
  height: calc(100vh - 235px);
}
.data-grid-container-with-tabs {
  margin-top: 10px;
  height: calc(100vh - 235px);
}
.data-grid-container-with-types-under-tabs {
  margin-top: 10px;
  height: calc(100vh - 315px);
}
.data-grid-container-with-tabs-in-popup {
  margin-top: 10px;
  height: calc(100vh - 290px);
}

.content-container {
  margin: 0 10px 0 10px;
}

.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: rgb(170, 170, 170);
  // border: 16px solid #f3f3f3; __<<ngThemingMigrationEscapedComment1>>__
  // border-top: 16px solid #3498db; __<<ngThemingMigrationEscapedComment2>>__
  // border-radius: 50%;
  // width: 120px;
  // height: 120px;
  // animation: spin 2s linear infinite;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.bold {
  font-weight: bold;
}

.justify-r {
  justify-content: right;
}

.center {
  text-align: center;
}

.cutoff {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

/* THIS IS THE SPINNER BIT*/
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 15px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #313d4f;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
.yes-no-dialog-panel {
  .mat-dialog-container {
    border-radius: 4px !important;
  }
}
// universal styles
.data-source-table {
  width: 100%;
  td {
    color: $grey-dark;
    padding-left: 0px;
    padding-right: 0px;
    font-size: $text-size-desktop;
    font-weight: normal;
    height: 75px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 0px;
  }
  th {
    color: $grey-dark;
    padding-left: 0px;
    padding-right: 0px;
    font-size: $text-size-desktop;
    height: 75px;
    padding-top: 0px !important;
    padding-left: 0px;
    font-weight: bold;
    padding-bottom: 15px !important;
  }
  .table-arrow-icon {
    font-weight: bold;
    font-size: $text-size-desktop;
    top: 2px;
    position: relative;
    height: 15px;
    padding-left: 5px;
  }
  tr.mat-footer-row {
    background-color: #fbf9f9;
  }
  tr.mat-row {
    transition: transform 100ms;
    height: 75px !important;
    &:hover {
      background-color: $grey-light;
      cursor: pointer;
    }
  }
  tr.selected,
  mat-table mat-row.selected {
    background-color: $grey-light;
    cursor: pointer;
    transform: scale(1.02);
  }
  tr:focus {
    outline: none !important;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    box-shadow: $lists-focus-box-shadow !important;
    -webkit-box-shadow: $lists-focus-box-shadow !important;
    -moz-box-shadow: $lists-focus-box-shadow !important;
  }
  .cost-right-align {
    text-align: right !important;
    padding-right: 20px !important;
  }
}

.basic-button {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  &.cdk-program-focused .mat-button-focus-overlay {
    opacity: 0 !important;
  }
  &.cdk-keyboard-focused .mat-button-focus-overlay {
    opacity: 0 !important;
  }
  &.mat-button:hover .mat-button-focus-overlay {
    opacity: 0 !important;
  }
  &.mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: 0 !important;
  }
}

.individual-button {
  @extend .basic-button;
  background-color: $secondary-blue-variant !important;
  color: $white !important;
  &:hover {
    background-color: $secondary-blue-dark !important;
  }
  &:focus {
    color: #deeffa !important;
  }
  &:disabled {
    background-color: $grey !important;
  }
}

.individual-button-inactive {
  @extend .individual-button;
  background-color: $primary-blue-hl !important;
}

.secondary-button {
  @extend .basic-button;
  color: $secondary-blue-variant !important;
  background-color: $white !important;
  border: solid thin $secondary-blue-variant !important;
  &:hover {
    color: $secondary-blue-dark !important;
    border: solid 2px $secondary-blue-dark !important;
    background-color: $white !important;
  }
  &:focus {
    background-color: #deeffa !important;
  }
  &:disabled {
    color: $grey !important;
    border: solid thin $grey !important;
  }
}

.secondary-button-inactive {
  @extend .secondary-button;
  color: $primary-blue-hl !important;
  border: solid thin $primary-blue-hl !important;
}

.responsive-font-size {
  font-size: $text-size-desktop !important;
}

.responsive-button-size-small {
  height: $btn-height-desktop;
  width: $btn-width-desktop-small;
}
.responsive-button-size {
  height: $btn-height-desktop;
  width: $btn-width-desktop;
}
.responsive-button-2 {
  height: $btn-height-2-desktop;
  width: $btn-width-2-desktop;
  font-size: $text-size-desktop !important;
}
.display-none {
  display: none;
}

mat-divider {
  border-top-width: thin !important;
}

.form-btn-container {
  text-align: right;
  margin-top: 15px;
  .form-save {
    height: $dg-btn-height;
    width: $dg-btn-width;
    border: 2px solid $secondary-blue-variant;
    background-color: $secondary-blue-variant;
    color: $white;
    margin: 10px 0px 0px 10px;
  }
  .form-cancel {
    height: $dg-btn-height;
    width: $dg-btn-width;
    border: 2px solid $secondary-blue-variant;
    color: $secondary-blue-variant;
    opacity: 0.6;
    margin: 10px 0px 0px 10px;
  }
}

div.stock-history-wrapper .generic-data-grid {
  width: auto;

  .dx-datagrid div.dx-toolbar {
    padding: 0px 10px;
  }
}
div.factors-wrapper .factors-data-grid {
  width: auto;

  .dx-datagrid div.dx-toolbar {
    padding: 0px 10px;
  }
}
div.supplier-inventory-codes-wrapper .generic-data-grid {
  width: auto;

  .dx-datagrid div.dx-toolbar {
    padding: 0px 10px;
  }
}
div.tax-rates-wrapper .generic-data-grid {
  width: auto;

  .dx-datagrid div.dx-toolbar {
    padding: 0px 10px;
  }
}

div.tax-names-wrapper .generic-data-grid {
  width: auto;

  .dx-datagrid div.dx-toolbar {
    padding: 0px 10px;
  }
}

div.recipe-ingredients-wrapper .generic-data-grid {
  width: auto;
  max-height: 375px;

  .dx-datagrid div.dx-toolbar {
    padding: 0px 10px;
  }
}

div.add-sales-special-product-list-grid-wrapper .generic-data-grid {
  width: auto;

  .dx-datagrid div.dx-toolbar {
    padding: 0px 10px;
  }
}

div.multi-item-barcode-wrapper .generic-data-grid {
  width: auto;
}

div.user-roles-wrapper .user-roles-data-grid {
  width: auto;
  max-height: 470px;
}

div.purchase-order-item-list {
  height: 100%;
}

div.stock-creation-template-factors-grid-container .generic-data-grid {
  width: auto;

  .dx-datagrid div.dx-toolbar {
    padding: 0px 10px;
  }
}

div.stock-creation-template-locations-grid-container .generic-data-grid {
  width: auto;

  .dx-datagrid div.dx-toolbar {
    padding: 0px 10px;
  }
}

div.select-stocktake-group .stock-group-data-grid .generic-data-grid {
  width: 100%;
}

.dx-popup-content {
  padding: 10px !important;
}
.dx-toolbar-common {
  margin: 0px 0px 25px 0px;
}
.red {
  color: #d55152;
}
.green {
  color: #61b95f;
}
.orange {
  color: #FFA500;
}
.dark-blue {
  color: $secondary-blue-variant;
}
.greyed-out-cell {
  background-color: #dedede !important;
}
.greyed-out-cell-alt {
  background-color: #ebebeb !important;
}
.dx-scroll-common {
  width: 100%;
  height: 100%;
}


.verified {
  padding: 0 8px;
  gap: 7px;
  height: 18px;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  background: #CAF4DF;
  color: #0DA44B !important;
  float: inline-end;
}

.unverified {
  padding: 0 8px;
  gap: 7px;
  height: 18px;
  border-radius: 4px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  background: #FEDADB;
  color: #D9121A !important;
  float: inline-end;
}


.dxi-error-icon {
  margin-left: 10px;
  pointer-events: none;
  font-weight: 700;
  position: absolute;
  top: 50%;
  margin-top: -9px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  font-size: 13px;
  background-color: #d9534f;
  color: #fff;
  content: "!";
  border-radius: 50%;
}

app-sysnet-common-header {
  .mat-toolbar-multiple-rows {
    min-height: 50px !important;
    height: auto;
  }
  mat-toolbar-row {
    min-height: 50px;
    height: auto !important;
  }
}
@media screen and (max-width: 800px) {
  .dx-datagrid-group-panel .dx-group-panel-message {
    color: white;
  }
  .data-grid-container {
    height: calc(100vh - 201px);
    min-height: 285px;
  }
  .content-container {
    margin: 0 10px 0 10px;
  }
  .yes-no-dialog-panel {
    .mat-dialog-container {
      margin-left: -20px;
      margin-right: -20px;
      width: 120%; // a bit of a hack but because using -20px need to compensate 100% width is not the full screen anymore.
    }
  }
  .data-source-table {
    padding-top: 0px;
    th {
      font-size: $text-size-list-mobile;
      height: 68px;
    }
    td {
      font-size: $text-size-list-mobile;
      height: 68px;
    }
    tr.mat-row {
      height: auto !important;
    }
  }
  .responsive-font-size {
    font-size: $text-size-mobile !important;
  }

  .responsive-button-size {
    height: $btn-height-mobile;
    width: $btn-width-mobile;
  }
  .responsive-button-2 {
    height: $btn-height-2-mobile;
    width: calc(50% - (15px / 2));
    font-size: $text-size-mobile !important;
  }
  .responsive-button-size-small {
    height: $btn-height-mobile;
    width: $btn-width-mobile;
  }
}

::ng-deep #events {
  background-color: rgba(191, 191, 191, 0.15);
  padding: 20px;
  margin-top: 20px;
}

::ng-deep #events > div {
  padding-bottom: 5px;
}

::ng-deep #events > div:after {
  content: "";
  display: table;
  clear: both;
}

::ng-deep #events #clear {
  float: right;
}

::ng-deep #events .caption {
  float: left;
  font-weight: bold;
  font-size: 115%;
  line-height: 115%;
  padding-top: 7px;
}

::ng-deep #events ul {
  list-style: none;
  max-height: 100px;
  overflow: auto;
  margin: 0;
}

::ng-deep #events ul li {
  padding: 7px 0;
  border-bottom: 1px solid #dddddd;
}

::ng-deep #events ul li:last-child {
  border-bottom: none;
}

app-sysnet-common-header {
  .dx-tab-selected {
    background-color: #3c66b6 !important;
    color: white !important;
  }
  .dx-tab {
    background-color: white;
    padding: 5px 0px 5px 0px;
    font-weight: 500;
  }
  .dx-tabs-wrapper {
    border-radius: 4px;
  }
}

.sub-tabs {
  .dx-tabs {
    max-width: 300px;
    border: 0px;
    .dx-tabs-wrapper {
      .dx-tab {
        background-color: #fff;
        box-shadow: unset !important;
        padding: 9px;
      }
      .dx-tab-selected {
        color: #3c66b6;
        font-weight: 500;
        border-bottom: 3px solid #3c66b6;
        .dx-tab-text {
          color: #3c66b6;
        }
        &::after {
          border-right: 0px !important;
          border-left: 0px !important;
          border-top: 0px !important;
          border-bottom: 3px solid #3c66b6 !important;
        }
      }
    }
  }
}

#stockListDataGrid,
#stocktakeListDataGrid,
#recipesListDataGrid
  .dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused)
  > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td {
  background-color: #3c66b6 !important;
  color: #fff;
}

#stockListDataGrid,
#stocktakeListDataGrid,
#recipesListDataGrid
  .dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused)
  > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > tr
  > td {
  color: #fff;
}

.subtab-border {
  padding: 15px 0px 0px 0px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin-top: 0px !important;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 295px !important;
}

.dx-datagrid div.dx-toolbar {
  padding: 0px 5px;
}

.stocktake-details-header {
  .inner {
    .dx-field-item-label-text {
      color: #707070 !important;
      font-weight: 400 !important;
    }
  }
}

.stock-mobile {
  .stocktake-entry-container{
    .stocktake-entry-wrapper {
        .count-stocktake-container {
          height: calc(100vh - 175px);
          overflow: auto;
            .count-stocktake-wrapper {
                overflow: unset !important;
                padding-left: unset !important;

                dx-tab-panel {
                  .dx-tabs.dx-widget {
                    background-color: unset;
                    box-shadow: inset 0 -1px #ddd, inset 0 0px, inset 0px 0, inset 1px 0;
                  }
                  .dx-tabs-wrapper {
                    .dx-item:nth-child(2) {
                      box-shadow: unset;
                    }
                  }
                }
                .count-stocktake-form {
                  min-width: 290px;
                }
                .stocktake-items-tabbed-grid {
                  height: calc(100vh - 265px);
                }
            }
        }
    }
  }
}

.datasymbol-barcode-wrapper {
  height: calc(100vh - 235px);
  #datasymbol-barcode-viewport {
      height: 100% !important;
      background: #ddd;
      #datasymbol-barcode-viewportDATASYMBOLTAG {
          height: 100% !important;
          #datasymbol-barcode-viewportDATASYMBOLVIDEOTAG {
              height: 100% !important;
          }
      }
  }
}

@media screen and (max-height: 500px) {
  .datasymbol-barcode-wrapper {
    height: calc(100vh - 80px);
  }
}